.App {
  text-align: center;
  font-family: 'Nunito';
}

.App-header {
  background-color: #010334;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-content {
  font-size: calc(8px + 2vmin);
}


button {
  border-radius: 10px;
  display: flex;
  position: relative;
  height: 50px;
  width: 150px;
  border: white solid 1px;
  justify-content: center;
  background-color: #366cf4;
  color: white;
  font-size: calc(15px + 1vmin);
  align-items: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    height: 40px;
    width: 20%;
    margin: 5px;
    transition: all 0.3s ease-in-out 0s;
  }
  &:hover{
      height: 55px;
      width: 160px;
      box-shadow: rgba(0, 0, 0, 0.596) 0px 5px 15px;
      font-size: calc(20px + 1vmin);
  }
}
