.science {
    &__content {
        display: flex;
    }
}

.scienceText {
    width: 50%;
    margin: 0 auto;
    text-align: left;
    white-space:break-spaces;
    text-align: center;
    @media (max-width: 800px) {
        width: 85%;
    }
}

.scienceIcons {
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0 auto;
    @media (max-width: 800px) {
        width: 85%;
        bottom: 0;
    }
    &__kurzgesagtBird {
        height: 200px;
    }
    &__blackhole {
        height: 100px;
    }
}

a {
    &:link {
        color: white;
    }
    &:visited {
        color: white;
    }
    &:hover {
        background-color: #366cf4;
    }
}