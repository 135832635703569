.AboutMe {
    background-color: #010334;
    color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    &__content {
        display: flex;
        flex-flow: column;
        flex: 1 1 auto;
        justify-content: space-between;
    }
}

.AboutMeText {
    margin: 5px 20px;
    display: flex;
    flex-direction: column;
    &__Link {
        transition: color .3s ease-in-out, box-shadow .3s ease-in-out;

        &:link {
            color: white;
        }
        &:visited {
            color: white;
        }
        &:hover {
            background-color: #366cf4;
        }
    }
}

.AboutMeIcons {
        position: relative;
        display: flex;
        bottom: 0;
        height: 300px;
        width: 100%;
        justify-content: space-around;
        @media screen and (max-width: 600px) {
            height: 200px;
        }
    &__BigBen{
        bottom: 0;
        height: 300px;
        @media screen and (max-width: 600px) {
            height: 200px;
        }
    }
    &__Gherkin{
        bottom: 0;
        height: 200px;
        @media screen and (max-width: 600px) {
            height: 130px;
        }
    }
}