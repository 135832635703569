.Skills {
    background-color: #010334;
    color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    &__content {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        margin: auto;
    }
}