.card {
    padding: 10% 0;
    &__image {
        width: 50%;
    }
}

.ProjectsCarousel,
.ProjectsScroll {
    display: flex;
    flex: auto;
}

.ProjectsScroll {
    display: flex;
    background: rgb(0,212,255);
    background: radial-gradient(circle, rgba(0,212,255,1) 0%, rgba(2,5,87,1) 35%, rgba(1,3,52,1) 50%, rgba(0,0,0,1) 100%);
    &__content {
        height: 50vh;
        overflow: scroll;
        margin: auto;
    }
}

