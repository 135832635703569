.skillsDisplay {
    display: flex;
    flex-direction: row;
    width: 100%;
    aspect-ratio: 3/1;
    margin: 30px;
    justify-content: center;
    @media (max-width: 800px) {
        flex-direction: column;
        max-width: 400px;
    }
    &__stack,
    &__experience,
    &__outlook {
        display: flex;
        width: 33% !important;
        height: auto !important;
        position: relative;
        aspect-ratio: 1/1;
        background-size: cover !important;
        @media (max-width: 800px) {
            width: 90% !important;
            margin: auto;
        }
        &:hover {
            box-shadow: rgba(0, 0, 0, 0.596) 5px 10px 15px;
        }
        &--active {
            width: 90% !important;
            height: auto !important;
            font-size: calc(15px + 1vmin) !important;
            overflow-y: scroll;
            background-size: cover !important;
            @media (max-width: 800px) {
                width: 100%;
            }
        }
        &--collapsed {
            width: 5% !important;
            height: auto !important;
            @media (max-width: 800px) {
                height: 40px !important;
                width: 90% !important;
            }
        }
    }
}

.skillButton {
    display: flex;
    flex-flow: column;
    &--active {
        height: -webkit-fill-available;
    }
    &__heading {
        &--collapsed {
            @media (min-width: 800px) {
                transform: rotate(-90deg);
            }
        }
    }
    &__stack,
    &__experience,
    &__outlook {
        &--collapsed {
            display: none;
        }
        &--active {
            padding: 0 25px;
            @media (max-width: 800px) {
                padding: 10px;
            }
        }
    }
    &__stack {
        &--active{
            width: 100%;
            @media (max-width: 800px) {
                padding: 10px 0;
            }
        }
    }
    &__experience,
    &__outlook {
        &--active{
            color: black;
            background-color: #f0f8ffcd;
            padding: 0 10%;
            b {
                color: blue;
            }
        }
    }
}



.stackContent {
    display: flex;
    justify-content: space-between;
}