.Interests {
    background-color: black;
    position: relative;
    display: flex;
    flex-flow: column;
    min-height: 100vh;
    &__content {
        display: flex;
        flex: 1;
        flex-direction: column;
    }
}

.buttonsContainer {
    display: flex;
    height: 50px;
    padding: 10px 10px;
    justify-content: space-around;
    @media screen and (max-width: 600px) {
        padding: 0;
    }
}

.displayContainer {
    display: flex;
    flex: 1 1 auto;
    margin: 10px;
    justify-content: center;
}

.gaming,
.movies,
.science,
.animals,
.defaultView {
    color: white;
    width: 100%;
}

.defaultView {
    @media (min-width: 800px) {
        padding: 0 15%;
    }
}



.gaming,
.movies,
.science,
.animals {
    &__content {
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        @media (max-width: 800px) {
            flex-direction: column;
        }
    }
}


.mySwiper {
    display: flex;
    background: rgb(0,212,255);
    background: radial-gradient(circle, rgba(0,212,255,1) 0%, rgba(2,5,87,1) 35%, rgba(1,3,52,1) 50%, rgba(0,0,0,1) 100%);
}


.swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #2903ff;
    opacity: 1;
    background: white !important;
  }
  
  .swiper-pagination-bullet-active {
    color: #fff;
    background: #007aff;
  }


  b {
    color: #a9d5ff;
  }