.gameCard {
    width: 45%;
    margin: 10px;
    @media screen and (max-width: 600px) {
        margin: 5px;
    }
}

.gamePoster {
    width: 100%;
    aspect-ratio: 1.8/1;
}