.welcome {
    display: flex;
    width: 100%;
    flex-flow: column;
    &__heading {
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;
        text-transform: uppercase;
    }

    &__message {
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;
        text-transform: capitalize;
    }

    &__links{
        display: flex;
        height: 100px;
        padding: 100px 50px;
        justify-content: space-around;
        flex-wrap: wrap;
        z-index: 2;
        @media screen and (max-width: 600px) {
            padding: 0;
        }
        &--about,
        &--interests,
        &--skills,
        &--projects
            {
                display: flex;
                position: relative;
                height: 50px;
                width: 150px;
                justify-content: center;
                background-color: #366cf4;
                color: white;
                border: white solid 1px;
                align-items: center;
                text-decoration: none;
                border-radius: 10px;
                margin: 0 10px 10px;

                text-transform: capitalize;
                animation-name: bottom-up;
                animation-duration: .8s;
                animation-fill-mode: both;
                transition: all .5s;
                @media screen and (max-width: 600px) {
                    width: 100px;
                }
                &:hover{
                    padding: 5px;
                    box-shadow: rgba(0, 0, 0, 0.596) 0px 5px 15px;
                }
            }
        &--about{
            animation-delay: .5s;
        }
        &--interests{
            animation-delay: .7s;
        }
        &--skills{
            animation-delay: .9s;
        }
        &--projects{
            animation-delay: 1.1s;
        }
    }
    &__constructionSign{
      position: absolute;
      bottom: -20px;
      @media screen and (max-width: 600px) {
        bottom: -40px;
      }
      left: 20px;
      width: 220px;
    }
}

@keyframes top-to-center {
  0% {
    top: -300px;
  }
  100% {
    top: 0px;
  }
}

@keyframes left-to-center {
  0% {
    left: -100%;
  }
  100% {
    left: 0px;
  }
}
@keyframes bottom-up {
  0% {
    top: 800px;
  }
  100% {
    top: 0px;
  }
}